import { Link } from 'gatsby';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
 

const FlexCard = (props) => {
  return (
    <div className="card">
      <div className={props.rowType}>
        <div className="col">
          <div className="card__image">
            <LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
          </div>
        </div>
        <div className="col">
          <div className="card__content">
            <h3>{props.header}</h3>
            <h6>{props.subheader}</h6>
            <hr />
            <p>{props.text}</p>
            {props.children}
            {props.externalURL && <a href={props.externalURL} target="_blank" rel="noopener noreferrer" ><button className={`btn ${props.btnStyle} ${props.btnCustom}`}>{props.btnText}</button></a> } 
            {props.phone && <a href={props.phone} target="_blank" rel="noopener noreferrer" className='phone' ><button className={`btn ${props.btnStyle} ${props.btnCustom || ""}`}>{props.btnText}</button></a> }
            {props.route && <Link to={props.route} ><button className={`btn ${props.btnStyle} ${props.btnCustom}`}>{props.btnText}</button></Link> }          </div>
        </div>
      </div>
    </div>
  );
};
export default FlexCard;